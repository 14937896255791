<template>
    <main class="container">
        <!-- ADD NEW MODAL -->
        <b-modal id="modal-1" size="lg" title="Agregar Producto a Factura" class="p-3" centered v-model="showNewModal" hide-footer>
            <form @submit.prevent="postData()" class="mx-3">           
                <div class="my-2" style="position:relative">                
                    <div class="row">
                        <div class="col-md-12">
                            <span class="text-secondary">Código</span><br>
                                <b-form-input v-model="textProduct" class="form-control bg-white border-secondary" list="products" id="product" @input="changeSelectedItem" ></b-form-input>
                                <datalist id="products">
                                    <option v-for="item in products" :key="item.id">{{ item.name }}</option>
                                </datalist>
                        </div>
                        <div class="col-md-6" v-if="this.newProduct.id>0 && textProduct!= ''">
                            <br>
                            <span class="text-secondary">Precio</span><br>
                            <input type="number" class="form-control bg-white border-secondary" v-model="newProduct.price">
                        </div>
                        <div class="col-md-6" v-if="this.newProduct.id>0 && textProduct!= ''">
                            <br>
                            <span class="text-secondary">Cantidad</span><br>
                            <input @input="updateDetails()" type="number" class="form-control bg-white border-secondary" v-model="newProduct.quantity">
                        </div>                                        
                    </div>
                    <div class="row mt-3">
                        <div class="col-12">
                            <h5 class="text-green-2">DETALLES</h5>
                            <hr>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-12">                        
                            <table class="w-100 table" v-if="rowsFields.length > 0">
                                <thead>
                                    <th class="text-green-1"  v-for="title of Object.keys(rowsFields[0])" :key="title.id">{{title}}</th>
                                </thead>
                                <tbody>
                                    <tr v-for="row of rowsFields" :key="'row'+row.id">  
                                        <td class="text-dark" v-for="(item, i) of Object.values(row)" :key="'td'+row.id+'c'+i">  
                                            <span v-if="i ===0" class="text-dark">{{item}}</span>

                                            <input v-else required class="form-control text-dark fw-bold bg-white" v-model="row[Object.keys(rowsFields[0])[i]]"
                                                    type="text">
                                        </td>                                                          
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div class="mt-5 bg-white text-end" style="margin:-16px -32px; padding:1rem 0px">
                    <button class="btn-3  me-2" type="button" @click="showNewModal=false">Cancelar <i class="fas fa-times ms-2"></i></button>
                    <button class="me-4 btn-1" type="submit" >Guardar <i class="fas fa-save ms-2"></i></button>
                </div>
            </form>
        </b-modal>

        <div class="header h3 mt-2">Ingreso de Factura</div>

        <div class="container opaque p-3 px-4" style="min-height:75vh; background:rgba(0,0,0,0.5);">
            <div class="row">
                <div class="col-md-3">
                    <b>No. de Factura</b>
                    <input type="text" class="form-control text-white" v-model="invoice.id">
                </div>
                <div class="col-md-3">
                    <b>Serie de Factura </b>
                    <input type="text" class="form-control text-white" v-model="invoice.serie">
                </div>

                <div class="col-md-3">
                    <b>Proveedor:</b> 
                    <select class="form-control select text-white" v-model="invoice.providerId">
                        <option :value="undefined" disabled selected>* seleccionar</option>
                        <option v-for="provider in providers" :value="provider.id" :key="provider.id">
                            {{provider.name}}
                        </option>
                    </select>
                </div>
                <div class="col-md-3">
                    <b>Fecha de Emisión:</b>
                    <input type="date" class="form-control text-white" v-model="invoice.invoiceDate">
                </div>            
            </div>
            <hr>
            <div class="row">
                <div class="col">
                    <button @click="showModal()" class="btn-2 float-end"><i class="fas fa-plus me-2"/> Agregar Producto</button>
                </div>
            </div>

            <div class="row my-3">
                <div class="col-12">
                    <table class="table">
                        <tr v-for="product in invoice.products" :key="product.productId">
                            <td>{{product.productId}}</td>
                            <td>{{product.name}}</td>
                            <td>{{product.price}}</td>
                            <td>{{product.quantity}}</td>
                        </tr>
                    </table>
                </div>
            </div>

        </div>
            <div class="mt-1 text-end" >
                <button class="btn-3  me-2" type="button" @click="$router.push('/purchases')">Cancelar <i class="fas fa-times ms-2"></i></button>
                <button class="me-4 btn-1" type="button" @click.prevent="postInvoice()" >Guardar <i class="fas fa-save ms-2"></i></button>
            </div>
    </main>
</template>

<script>
export default {    
    data(){
        return{
            showNewModal:false,
            invoice:{
                products:[
                    // {id:1, name:"Casco", price: 150, quantity:2 },
                ]
            },
            productList:[
                {id:1, category:"Uniforme",  categoryId:1,  warehouse:"Uniforme", warehouseId:1, name:"Casco"},
                {id:2, category:"Vehiculos", categoryId:4,  warehouse:"Vehículos", warehouseId:2, name:"Motocicleta"},
            ],
            newProduct:{name: '', details:[]},
            products:[],
            warehousefields: [],
            rowsFields: [],
            textProduct: '',
            table: 'purchases',
            providers: []
        }
    },
    async mounted(){
        await this.getProducts(this.newProduct.id)
        await this.getProviders()
    },
    methods:{
        async postInvoice(){
            try {
                this.invoice = JSON.parse(localStorage.getItem('invoice'))
                //console.log(this.invoice)
                this.invoice.substationId = 3
                let res = await this.$store.dispatch('post', {path: this.table + '/new', data: this.invoice});
                //console.log(res)
                if (res.error){
                    this.$swal({title:"Aviso", text:"Error: " + res.error , icon:"error"})
                }
                else{
                    // this.items = res
                    // this.showNewModal=false
                    // this.getData()
                    // this.newItem= {}
                    this.$swal({title:"Aviso", text:"Datos almacenados correctamente", icon:"success"})
                }
            } catch (error) {
                console.error('error', error)
                this.$swal({title:"Aviso", text:"Error: " + error , icon:"error"})
            }
        },
        clearData(){
            this.newProduct = {name:'', id: 0}
            this.rowsFields = []
            this.textProduct= ''
        },
        showModal(){
            this.clearData()
            this.showNewModal=true; 
        },
        async postData(){
            //ordeno array para insersión en tabla purchaseDetailFields
            let i = 1
            let ii = 1
            let purchaseDetailFields = []
            console.log('rowsfields', this.rowsFields)
            console.log('')
            for await(let el of this.rowsFields){

                for (let it in el){
                    let warehouseFieldId = await this.warehousefields.filter(x => x.warehouseFieldName === it)
                    if(warehouseFieldId.length>0){
                        let dataTmp = {
                            warehouseFieldId: warehouseFieldId[0].warehouseFieldId,
                            value: el[it],
                            productId: this.newProduct.id,
                            element: ii
                        }
                        purchaseDetailFields.push(dataTmp)
                    }
                    i++
                }
                ii++
            }
            console.log('purchaseDetailFields---',purchaseDetailFields)
            // ordeno 
            let dataProduct = {
                productId: this.newProduct.id,
                price:this.newProduct.price,
                quantity: this.newProduct.quantity,
                name: this.textProduct,
                rowsFields: this.rowsFields,
                purchaseDetailFields: purchaseDetailFields
            }
            this.invoice.products.push(dataProduct)
            console.log('invoice', this.invoice)
            localStorage.setItem('invoice', JSON.stringify(this.invoice))
            //console.log('invoice!!!',this.invoice)
            this.clearData()
            this.showNewModal=false; 

        },
        help(){
            //console.log("ahora rowsfields.... ")
            //console.log(this.rowsFields)
        },
        async updateDetails(){
            //console.log(this.newProduct.quantity)
            await this.getAllFields(this.newProduct.id)
            let i = 1
            //console.log('this.warehousefields', this.warehousefields)
            let rows= []
            let row= {}            
            for (let ii = 1;  ii <= this.newProduct.quantity; ii++) {
                let row= {}
                row['id']=ii
                for await (let item of this.warehousefields){
                    row[item.warehouseFieldName]= item.dataType === 'Número' ? 0 : item.dataType === 'Texto' ? '' : null
                }
                //console.log(row)
                rows.push(row)
            }
            //console.log('---------------->',rows)
            this.newProduct.details = rows
            this.rowsFields = rows
        },
        async changeSelectedItem(e){
            //console.log('textprod: ',this.textProduct)
            try {
                //console.log(e)
                let data = this.products.filter(x => x.name == e)
                this.newProduct.id = data[0].id
                this.newProduct.name = data[0].name
            } catch (error) {
                this.newProduct.id = 0
                this.newProduct.name = ''
                console.error(error)
            }
        },
        async getAllFields(id){
            try {
                let res = await this.$store.dispatch('get', {path: 'warehouseFields/getAllFields/'+ id});
                //console.log(res)
                if (res.length >0){
                    this.warehousefields = res
                }
            } catch (error) {
                this.warehousefields = []
                console.error('error', error)
            }            
        },
        async getProducts(){
            try {
                let res = await this.$store.dispatch('get', {path: 'products/getAll'});
                //console.log(res)
                if (res.length >0){
                    this.products = res
                }
            } catch (error) {
                this.products = []
                console.error('error', error)
            }
        },
        async getProviders(){
            try {
                let res = await this.$store.dispatch('get', {path: 'providers/getAll'});
                //console.log(res)
                if (res.length >0){
                    this.providers = res
                }
            } catch (error) {
                this.providers = []
                console.error('error', error)
            }
        }
    }
}
</script>

<style>

</style>